require(['jquery'], function($) {
    var typeTime;

    function typeTimer() {
        termsToType = [
            'Aqua-i Shower Enclosure',
            'MX Shower Tray',
            'Nuie Athena',
            'Trojancast Bath',
            'Bristan Taps',
            '3 Sided Shower Enclosure',
            'Aqua-i Shower Door',
            'Walk-In Shower Tray with Drying Area',
            'Biasi Boilers',
            'Splash Panels',
            'Slate Effect Shower Tray',
            'Rak Moon Rimless',
            'Niagara Observa Shower',
            'Faral Radiators',
            'Lakes Shower Tray',
            'April Harrogate Bath',
            'Rak Joy Wall Hung Vanity Unit',
            'Anti-Slip Shower Tray',
            'Aqua-i Shower Enclosure',
            'Telford Tempest Cylinder',
            'Traymate Shower Tray'
        ]
        var typeSpeed = 165
        var deleteSpeed = 75
        var strPos = 0
        var currentIndex = 0
        var str = termsToType[currentIndex]
		var searchBox = document.getElementById('search');

        if (searchBox !== null) {
            searchBox.placeholder = '';
        }

        function type() {
            if (strPos < str.length) {
                searchBox.placeholder = searchBox.placeholder.slice(0, -1);
                searchBox.placeholder += str.charAt(strPos) + '|'
                strPos++
                typeTime = setTimeout(type, typeSpeed)
            } else {
                typeTime = setTimeout(deleteText, deleteSpeed)
            }
        }
        if (searchBox !== null) {
        typeTime = setTimeout(type, typeSpeed)
        }

        function deleteText() {
            if (strPos >= 0) {
                var temp = str.substring(0, strPos)
                searchBox.placeholder = searchBox.placeholder.slice(0, -1);
                searchBox.placeholder = temp + '|';
                strPos--;
                typeTime = setTimeout(deleteText, deleteSpeed)
            } else {
                // Next Line
                if (currentIndex + 1 === termsToType.length) {
                    currentIndex = 0
                } else {
                    currentIndex++
                }
                str = termsToType[currentIndex]
                typeTime = setTimeout(type, typeSpeed)
            }
        }
    }
    $('#search').focus(function () {
        clearTimeout(typeTime);
        this.placeholder = '';
    });
    $('#search').blur(function () {
        clearTimeout(typeTime);
        this.placeholder = '';
        typeTimer();
    });
    typeTimer();
});

define("js/typing-animation", function(){});

