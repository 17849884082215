define('Explore_AmastyProductlabels/js/sendpostcode',[
    "jquery",
    "jquery/ui"
], function($){
    "use strict";
     
    function main(config, element) {
        var $element = $(element);
        var AjaxUrl = config.AjaxUrl;
         
        var dataForm = $('#checkdeliverable-form');
        dataForm.mage('validation', {});
         
        $(document).on('click','.calc-checkdeliverable.submit',function() {
             
            if (dataForm.valid()){
            event.preventDefault();
                var param = dataForm.serialize();
               // alert(param);
                    $.ajax({
                        showLoader: true,
                        url: AjaxUrl,
                        data: param,
                        type: "POST"
                    }).done(function (data) {
						
						$("#delivery-postcode-content").modal("closeModal");
						//alert(data['deliverable-status']+' || '+data['reloadpage']+' || '+data['onwhichpage']);
						if ( ( data['deliverable-status'] == 'allitems-deliverable' ) && ( data['onwhichpage'] == 'chechoutpageindex' ) )
						{
							//location.reload(true);
							$('.checkout-index-index .exploretopbar').hide();
						}
						else
						{
							location.reload(true);
						}
						//location.reload(true);
						//alert(data['deliverable-status']);
						
                        //$('.note').html(data['deliverable-status']);
                        //$('.note').css('color', 'red');
                        ///document.getElementById("checkdeliverable-form").reset();
                        return true;
                    });
                }
            });
			
			$(document).on('click','.clearpostcode-checkdeliverable',function() {
             
			 var clearurl;
			// https://finalpw.plumbingworld.co.uk//exploreproductlabels/index/chechoutpageindex
			// https://finalpw.plumbingworld.co.uk//exploreproductlabels/index/chechoutpageindexindex/clearpostcode
			 clearurl = AjaxUrl+'index/clearpostcode';
			 
			 //console.log('before:'+clearurl);
			 if( $(this).hasClass('oncheckout') )
			 {
				 clearurl = AjaxUrl;
				 //AjaxUrl = clearurl.replace("chechoutpageindexindex/index/clearpostcode", "");
				 clearurl = clearurl.replace("index/chechoutpageindexindex/clearpostcode", "");
				 clearurl = clearurl.replace("index/chechoutpageindex", "");
				 clearurl = clearurl+'index/clearpostcode';
			 }
			 
			 //console.log('after:'+clearurl);
			 
            	if (dataForm.valid()){
            event.preventDefault();
                var param = dataForm.serialize();
               // alert(param);
                    $.ajax({
                        showLoader: true,
                        url: clearurl,
                        data: param,
                        type: "POST"
                    }).done(function (data) {
						
						$('input[name="postcode"]').val('');
						location.reload(true);
						//alert(data);
                        //$('.note').html(data['deliverable-status']);
                        //$('.note').css('color', 'red');
                        ///document.getElementById("checkdeliverable-form").reset();
                        return true;
                    });
                }
            });
    };
return main;
     
     
});
