requirejs([
	'jquery',
	'Magento_Ui/js/lib/validation/utils',
	'jquery/ui',
	'jquery/validate',
	'mage/translate'
], function($, utils){
	'use strict';
	$.validator.addMethod(
		"validate-zip-gb",
		function (v) {
			return $.mage.isEmptyNoTrim(v) || /(^[a-zA-Z]{2}[0-9]{2}\s?[0-9]{1}[a-zA-Z]{2}$)|(^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}\s?[0-9]{1}[a-zA-Z]{2}$)|(^[a-zA-Z]{2}[0-9]{1}\s?[0-9]{1}[a-zA-Z]{2}$)|(^[a-zA-Z]{2}[0-9]{1}[a-zA-Z]{1}\s?[0-9]{1}[a-zA-Z]{2}$)|(^[a-zA-Z]{1}[0-9]{2}\s?[0-9]{1}[a-zA-Z]{2}$)|(^[a-zA-Z]{1}[0-9]{1}\s?[0-9]{1}[a-zA-Z]{2}$)/i.test(v);
		},
		$.mage.__('Please provide a valid postcode.')
	);
});
define("Explore_AmastyProductlabels/js/lib/validation/rules", function(){});

