define('Explore_Extend/js/view/tax',[
    'Magento_Customer/js/customer-data',
    'ko',
    'uiComponent',
    'jquery',
    'mage/translate'
], function (customerData, ko, Component, $) {
    'use strict';

    var taxDisplay = ko.observable('');

    return Component.extend({
        defaults: {
            taxDisplay: taxDisplay
        },

        initialize: function () {
            this.taxDisplay = customerData.get("tax-display")();
			//console.log('lastchangesd:'+this.taxDisplay);
            if (this.taxDisplay) {
                if (this.taxDisplay == 1) {
                    $('.form-session .exc-vat-select').addClass('selected');
                    $('.form-session .inc-vat-select').removeClass('selected');
					$('.togglevat').addClass("selected");
                    this.updatePriceBlockVisibility();
                }
                else {
                    $('.form-session .inc-vat-select').addClass('selected');
                    $('.form-session .exc-vat-select').removeClass('selected');
					$('.togglevat').removeClass("selected");
                    this.updatePriceBlockVisibility();
                }
            } else {
                customerData.set("tax-display", 2);
                this.taxDisplay = 2;
				//$('.togglevat').removeClass("selected");
                this.ajaxTaxSwitch();
            }
            this._super();

            $(document.body).on('contentUpdated', '#amasty-shopby-product-list', this.updatePriceBlockVisibility.bind(this));
			
			$(document.body).on('contentUpdated', '#layer-product-list', this.updatePriceBlockVisibility.bind(this));

            $(document.body).on('contentUpdated', '#form-validate', this.updatePriceBlockVisibility.bind(this));

            $(document.body).on('contentUpdated', '#details-reload', this.updatePriceBlockVisibility.bind(this));
			
        },

        changeTax: function (data, event, element) {
            var selectedOption = $(element).parent().find('li');
			//console.log('before changeTax:'+this.taxDisplay);
            if (selectedOption.hasClass("exc-vat-select") && this.taxDisplay != 1) {
                this.taxDisplay = 1;
                this.ajaxTaxSwitch();
				$('.togglevat').removeClass("selected");
                this.updatePriceBlockVisibility();
                this.changeTaxOption(element);
            } else if (selectedOption.hasClass("inc-vat-select") && this.taxDisplay != 2) {
                this.taxDisplay = 2;
                this.ajaxTaxSwitch();
				$('.togglevat').addClass("selected");
                this.updatePriceBlockVisibility();
                this.changeTaxOption(element);
            }
			//console.log('after:'+this.taxDisplay);
        },

        updatePriceBlockVisibility: function() {
            if (this.taxDisplay == 1) {
                $('.form-session .text-item').text('Exc. VAT');
                $('span[data-label="Incl. Tax"]').css('display','none');
                $('span[data-label="Excl. Tax"]').css('display','block');
				
				$('.save-excltax').css('display','block');
				$('.save-incltax').css('display','none');
				
				$('.price-box.price-final_price').css("visibility", "visible");
				$('li.togglevat').css("visibility", "visible");
				
            }
            else {
                $('.form-session .text-item').text('Inc. VAT');
                $('span[data-label="Incl. Tax"]').css('display','block');
                $('span[data-label="Excl. Tax"]').css('display','none');
				
				$('.save-excltax').css('display','none');
				$('.save-incltax').css('display','block');

				$('.price-box.price-final_price').css("visibility", "visible");
				$('li.togglevat').css("visibility", "visible");
				

			}
        },

        ajaxTaxSwitch: function () {
            var self = this;
			
			
			$.ajax({
				url: self.changeLocation,
				data: { tax: this.taxDisplay },
				type: 'POST',
				success: function(response) {
					if (response.result == 'failed') {
						alert($.mage.__(response.message));
					}
				},
				error: function () {
					alert($.mage.__('Failed to switch tax.'));
				}
			});
			
            /*$.ajax({
                type: "POST",
                data: { tax: this.taxDisplay },
                url: self.changeLocation,
            }).success(function(response) {
                if (response.result == 'failed') {
                    alert($.mage.__(response.message));
                }
            }).fail(function() {
                alert($.mage.__('Failed to switch tax.'));
            });*/
        },

        changeTaxOption: function (element) {
            $(".tax-vat-ul > li").toggleClass("selected");
            //$(element).addClass("selected");
            customerData.set("tax-display", this.taxDisplay);
        },

        openList: function (data, event, element) {
            if ($(element).hasClass('active')) {
                $(element).removeClass('active');
            } else {
                $(element).addClass('active');
            }

            $('body').toggleClass('active-price');
            event.stopPropagation();
        }
    });
});
